import { useEffect, useState } from "react";
import DealCard, { DealType } from "./deal-card";
import useFetch from "../../common/hooks/use-fetch";
import { useParams } from "react-router-dom";
import { Col, Layout, Row } from "antd";

export default function ViewDeals() {
  const { vendorId } = useParams();
  const [data, setData] = useState<DealType[]>([]);
  const { get } = useFetch();
  useEffect(function () {
    refreshData();
  }, []);
  async function refreshData() {
    const resp = await get(`/Discount/active-deals/${vendorId}`);

    if (resp) {
      setData(resp as DealType[]);
    }
  }
  return (
    <Layout style={{ maxWidth: "450px", margin: "auto", padding: "20px" }}>
      <Row gutter={[16, 16]}>
        {data.map((deal) => (
          <Col span={24} key={deal.Id}>
            <DealCard {...deal} />
          </Col>
        ))}
      </Row>
    </Layout>
  );
}
