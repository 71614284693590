import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import useFetch, { USER_KEY, useSuccess } from "../../../common/hooks/use-fetch";
import { loggedInState, useRoot } from "../../../common/root-context";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "../../../common/hooks/use-query";
import { useLogin } from "./../login-form";
import { validatePasswordRules } from "../../../common/utils";

const ResetPasswordForm: React.FC = () => {
  const { post } = useFetch();
  const login = useLogin();
  const success = useSuccess();
  const location = useLocation();

  const onFinish = async (values: any) => {
    const userInfo: any = await post("/auth/reset-password", {
      ...values,
    });
    login({ username: "" + userInfo.Id, password: values.Password });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
   const resendOTP=async (event: any)=>{
    console.log(event);
      await post("/auth/send-recovery-email", {
       Email: location.state
     });
     success("OTP has been sent again!") 
   }

  return (
    <Form
      layout="vertical"
      name="basic"
      className="auth-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="OTP"
        name="Otp"
        rules={[{ required: true, message: "Please input your OTP!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="Password"
        rules={[
          { required: true, message: "Please input your Password!" },
          () => ({
            validator(_, value) {
              return validatePasswordRules(value);
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Repeat Password"
        name="Password2"
        rules={[
          { required: true, message: "Please input your Repeat Password." },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && getFieldValue("Password") !== value) {
                return Promise.reject(
                  new Error("The two passwords that you entered do not match.")
                );
              }
              return validatePasswordRules(value);
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-main">
          Update Password
        </Button>
      </Form.Item>
       <Button type="primary" onClick={resendOTP} className="btn-main">
          Resend OTP
        </Button> 
    </Form>
  );
};

export default ResetPasswordForm;
