import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import useFetch, { USER_KEY } from "../../../common/hooks/use-fetch";
import { loggedInState, useRoot } from "../../../common/root-context";
import { useNavigate } from "react-router-dom";
import useQuery from "../../../common/hooks/use-query";
import useAuthNavigate from "../../../common/hooks/use-auth-navigate";

const FpEmail: React.FC = () => {
  const { post } = useFetch();
  const authNavigate = useAuthNavigate();

  const onFinish = async (values: any) => {
    await post("/auth/send-recovery-email", values);
    authNavigate(`/reset-password`, {state: values.Email});
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      layout="vertical"
      name="basic"
      className="auth-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Email"
        name="Email"
        rules={[{ required: true, message: "Please input your Email!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-main">
          Get OTP
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FpEmail;
