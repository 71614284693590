import React, { useState } from "react";
import { Alert, Button, Checkbox, Col, Form, Input, Row } from "antd";
import useFetch, { USER_KEY, useError } from "../../common/hooks/use-fetch";
import { loggedInState, useRoot } from "../../common/root-context";
import { useNavigate, useParams } from "react-router-dom";
import { toLocalDateTime } from "../../common/utils";

const RedeemDeal: React.FC = () => {
  const { id } = useParams();
  const { get } = useFetch();
  const [discount, setDiscount] = useState<any>({});
  const [redeemError, setRedeemError] = useState<any>({});
  //   const root = useRoot();
  //   const navigate = useNavigate();
  //   const showError = useError();

  const onFinish = async (values: any) => {
    const resp = (await get("/Discount/redeem", {
      query: { ...values, dealId: id },
    })) as any;
    if (resp.Success) {
      setDiscount(resp.Redeem);
      setRedeemError({});
      return;
    }
    setRedeemError({ ...resp, error: true });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Row
        style={{
          flexDirection: "column",
          marginTop: "24px",
          alignContent: "space-around",
        }}
      >
        {discount.UserId && (
          <>
            <Col>
              <Alert
                style={{ maxWidth: "450px", minWidth: "400px" }}
                message="Congratulations...!"
                description={`You have redeemed the ${discount.DealName} deal`}
                type="success"
                showIcon
              />
            </Col>
            <Col style={{ marginTop: "10px" }}>
              {`${discount.UserName} @ ${toLocalDateTime(
                discount.CreatedDate
              )}`}
            </Col>
          </>
        )}
        {redeemError.error && (
          <Col>
            <Alert
              style={{ maxWidth: "450px", minWidth: "400px" }}
              message="Oops...!"
              description={redeemError.Message}
              type="error"
              showIcon
            />
          </Col>
        )}
        <Col style={{ paddingTop: "15px" }}>
          <Form
            style={{ maxWidth: "450px", minWidth: "400px" }}
            layout="vertical"
            name="basic"
            // className="auth-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-main"
                disabled={!!discount.Id}
              >
                Request Discount
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RedeemDeal;
