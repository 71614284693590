import { useEffect } from "react"

export default function BasePathRedirect(){

    useEffect(()=> {
        window.location.href = '/dbc';
    })

    return <>
    </>
}