import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import useFetch, { USER_KEY, useSuccess } from "../../common/hooks/use-fetch";
import useQuery from "../../common/hooks/use-query";
import { useLogin } from "./login-form";
import { validatePasswordRules } from "../../common/utils";

const SetPasswordForm: React.FC = () => {
  const { post } = useFetch();
  const {t, uid } = useQuery();
  const login = useLogin();
  const success = useSuccess();

  const onFinish = async (values: any) => {
    await post("/auth/set-password", {
      ...values,
      UserId: uid,
    });
    //navigate("/login");
    login({ username: uid, password: values.Password });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const resendOTP=async ()=>{
    await post("/auth/resend-otp", {
      UserId: uid
    });
    success("OTP has been sent again!")
  }

  return (
    <Form
      layout="vertical"
      name="basic"
      className="auth-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <p style={{ color: "#6c757d" }}>
        Your OTP has just been dispatched to your email address. Please don't
        forget to inspect your spam/junk folder just in case it's hiding there.
      </p>
      <Form.Item
        label="OTP"
        name="Otp"
        rules={[{ required: true, message: "Please input your OTP!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Set New Password"
        name="Password"
        rules={[
          { required: true, message: "Please input your Password!" },
          () => ({
            validator(_, value) {
              return validatePasswordRules(value);
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Repeat Password"
        name="Password2"
        rules={[
          { required: true, message: "Please input your Repeat Password." },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && getFieldValue("Password") !== value) {
                return Promise.reject(
                  new Error("The two passwords that you entered do not match.")
                );
              }
              return validatePasswordRules(value);
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-main">
          Update Password
        </Button>
      </Form.Item>
        <Button type="primary" onClick={resendOTP} className="btn-main">
          Resend OTP
        </Button>
    </Form>
  );
};

export default SetPasswordForm;
