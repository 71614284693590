import React, { PropsWithChildren } from "react";
import {
  ShoppingCartOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Card, Col, Row } from "antd";
import { BaseUrl } from "../../common/hooks/use-fetch";
import { useNavigate } from "react-router-dom";

const { Meta } = Card;

export default function DealCard(props: PropsWithChildren<DealType>) {
  const navigate = useNavigate()
  function handelRedeem(){
    navigate(`/redeem-deal/${props.Id}`)
  }
  return (
    <Card
      cover={
        props.Photo && <img
          style={{ width: "200px", margin: "auto" }}
          alt="example"
          src={`${BaseUrl}${props.Photo}`}
        />
      }
    // actions={[
    //   <ShoppingCartOutlined onClick={console.log} />,
    // ]}
    >
      <Meta
        // avatar={
        //   <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />
        // }
        title={props.Name}
        description={props.Description}
      />
      <Row justify="space-around">
        <Col>
          <Button shape="round" danger style={{ margin: "auto" }} icon={<ShoppingCartOutlined />} onClick={handelRedeem} >Redeem</Button>
        </Col>
      </Row>
    </Card>
  );
}

export type DealType = {
  Id: number;
  Name: string;
  Description: string;
  ActualPrice: string;
  DiscountPrice: string;
  IsActive: string;
  ExpiryDate: string;
  Photo: string;
};
