import React, { PropsWithChildren, useEffect, useMemo, useState, lazy, Suspense } from "react";
import { LockOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { MenuProps, Modal, Spin } from "antd";
import { Layout, Menu, theme } from "antd";
import { useNavigate, Routes, Route, Navigate, useParams } from "react-router-dom";
import useFetch from "./common/hooks/use-fetch";
import { loggedInState, useRoot, useUser } from "./common/root-context";
import AppMenu from "./common/menu";
import RedeemDeal from "./features/discount-redeem/redeem-deal";
import ViewDeals from "./features/discount-redeem/view-deals";
import ViewProfile from "./features/profile/view";
import BasePathRedirect from "./components/BasePathRedirect";
import ConfigEmail from "./features/auth/config-email-form";
import FpEmail from "./features/auth/forgot-password/email-form";
import ResetPasswordForm from "./features/auth/forgot-password/reset-password";
import Login from "./features/auth/login-form";
import SetPasswordForm from "./features/auth/set-password-form";
import AuthLayout from "./layouts/auth-layout";



const DealForm = lazy(() => import("./features/discount-deal/deal-form"));
const Deals = lazy(() => import("./features/discount-deal/deals"));
const VendorForm = lazy(() => import("./features/discount-vendor/vendor-form"));
const Vendors = lazy(() => import("./features/discount-vendor/vendors"));
const UserLeads = lazy(() => import("./features/leads/user-leads"));
const OrganizationForm = lazy(() => import("./features/organization/organization-form"));
const ViewThemeForm = lazy(() => import("./features/profile/view-theme-form"));
const RedirectLinks = lazy(() => import("./features/redirect-link/redirect-link"));
const ChangePasswordForm = lazy(() => import("./features/user/change-password-form"));
const UserSettings = lazy(() => import("./features/user/settings"));

const Dashboard = lazy(() => import("./features/dashboard/dashboard"));
const Organizations = lazy(() => import("./features/organization/organizations"));
const UserForm = lazy(() => import("./features/user/user-form"));
const UsersAll = lazy(() => import("./features/user/users-all"));
const UserProfileForm = lazy(() => import("./features/user/user-profile-form"));
const CardPlanForm = lazy(() => import("./features/card/card-plan-form"));

const { Header, Content, Sider } = Layout;

const App: React.FC = () => {
  const root = useRoot();
  return (
    <Spin spinning={!!root?.blocked} tip="Loading...">
      <Routes>
        <Route path="/profile/:id" element={<ViewProfile />} />
        <Route path="/vendor/:vendorId" element={<ViewDeals />} />
        <Route path="/redeem-deal/:id" element={<RedeemDeal />} />
        <Route
          path="*"
          element={
            <>
              <AuthenticatedTemplate />
              <UnAuthenticatedTemplate />
            </>
          }
        />
      </Routes>
    </Spin>
  );
};

function RoutesWrapper(props: PropsWithChildren) {
  const user = useUser();
  const root = useRoot();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 576);
  const menuItem = useMenuItems();
  const userMenuItems: MenuProps['items'] = [
    {
      label: user?.Email,
      key: 'UserMenu',
      icon: <UserOutlined />,
      children: [
        {
          label: 'Change Password',
          key: 'changepassword',
          icon: <LockOutlined />,
          onClick: () => navigate("/change-password"),
        },
        {
          label: 'Logout',
          key: 'logout',
          icon: <LogoutOutlined />,
          onClick: () => root?.logout(),
        },
      ],
    }
  ];

  return (
    <Layout>
      <Header className="header">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="org-name">{user?.OrganizationName}</div>
          <Menu
            mode="horizontal"
            theme="dark"
            selectable={false}
            style={{ justifyContent: "flex-end", minWidth: 150, flex: "auto" }}
            items={userMenuItems}
          >
          </Menu>
        </div>
      </Header>
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={200}
          style={{ background: colorBgContainer }}
        >
          <Menu
            mode="inline"
            style={{ height: "100%", borderRight: 0 }}
            onClick={(item) => navigate(item.key)}
            items={menuItem}
          />
        </Sider>
        <Layout style={{ padding: "24px" }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              overflow: "initial",
            }}
          >
            <Suspense fallback={<Spin style={{position: 'fixed', top: '50%', left: '50%'}} spinning={true} tip="Loading..."></Spin>}>
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/organizations" element={<Organizations />} />
                <Route path="/organization/form" element={<OrganizationForm />} />
                <Route path="/org/form" element={<OrganizationForm />} />
                <Route path="/user-form" element={<UserForm />} />
                <Route path="/users" element={<UsersAll />} />
                <Route path="/user/settings" element={<UserSettings />} />
                <Route path="/user-profile" element={<UserProfileForm />} />
                <Route path="/leads" element={<UserLeads />} />
                <Route path="/vendors/all" element={<Vendors />} />
                <Route path="/vendors/form" element={<VendorForm />} />
                <Route path="/deals/all" element={<Deals />} />
                <Route path="/deals/form" element={<DealForm />} />
                <Route
                  path="/my-profile"
                  element={<UserProfileForm userId={user?.Id} />}
                />
                <Route path="/card-plan" element={<CardPlanForm />} />
                <Route path="/redirect-links" element={<RedirectLinks />} />
                <Route path="/change-password" element={<ChangePasswordForm />} />
                <Route path="/profile-design" element={<ViewThemeForm />} />
                {/* <Route path="/profile/:id" element={<ViewProfile />} /> */}
              </Routes>
            </Suspense>
            <ConfirmModel />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

function ViewCardRoute() {
  return <Route path="/profile/:id" element={<ViewProfile />} />;
}

function UnAuthenticatedTemplate(props: PropsWithChildren) {
  const root = useRoot();
  return root?.loggedInStatus === null ||
    root?.loggedInStatus === loggedInState.failed ? (
    <AuthLayout>
      <Routes>
        <Route path="/" element={<BasePathRedirect />} />
        <Route path="/login" element={<BasePathRedirect />} />
        <Route path=":orgSlug" element={<LoginNavigate />} />
        <Route path=":orgSlug/login" element={<Login />} />
        <Route path=":orgSlug/configure-email" element={<ConfigEmail />} />
        <Route path=":orgSlug/set-password" element={<SetPasswordForm />} />
        <Route path=":orgSlug/forgot-password" element={<FpEmail />} />
        <Route path=":orgSlug/reset-password" element={<ResetPasswordForm />} />
        {/* <Route path="/profile/:id" element={<ViewProfile />} /> */}
      </Routes>
    </AuthLayout>
  ) : (
    <></>
  );
}

function LoginNavigate() {
  const { orgSlug } = useParams();
  return <Navigate to={`/${orgSlug}/login`} />;
}

function ConfirmModel(props: PropsWithChildren) {
  const root = useRoot();
  const con = root?.confirmation;
  return (
    <Modal
      title="Confirmation"
      style={{ top: 20 }}
      open={!!con?.message}
      onOk={() => {
        con?.onOk();
        root?.setConfirmation(undefined);
      }}
      onCancel={() => root?.setConfirmation(undefined)}
    >
      <p>{con?.message}</p>
    </Modal>
  );
}

function AuthenticatedTemplate(props: PropsWithChildren) {
  const root = useRoot();
  const { get } = useFetch();
  useEffect(
    function () {
      if (root?.loggedInStatus === loggedInState.success) {
        (async function () {
          const user = await get("/auth/user");
          root?.setUser(user);
        })();
      }
    },
    [root?.loggedInStatus]
  );
  return root?.user &&
    root.user.Id &&
    root.loggedInStatus === loggedInState.success ? (
    <RoutesWrapper />
  ) : (
    <></>
  );
}

function useMenuItems() {
  const user = useUser();
  return useMemo(
    function () {
      return AppMenu.filter(
        (item) =>
          !item.roles?.length ||
          !!item.roles?.find((x: string) => user?.Roles.find((y) => y === x))
      );
    },
    [user]
  );
}

export default App;
