import { PropsWithChildren, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../common/hooks/use-fetch";

export default function ViewProfile() {
  const { id: slug } = useParams();
  const [data, setData] = useState<any | null>(null);
  const { get } = useFetch();
  useEffect(function () {
    if (slug) {
      getData(slug);
    }
  }, []);
  async function getData(id: string) {
    var resp = await get(`/profile/${id}`);
    setData(resp);
  }
  const contact = data?.Contacts[0] || {};
  const address = data?.Addresses[0] || {};
  return (
    <div className="profile-root">
      <div className="profile-item">
        <div></div>
      </div>
      <div className="profile-item summary">
        <div>{data?.FirstName + " " + data?.LastName}</div>
        <div>
          {contact.CountryCode} {contact.ContactNumber}
        </div>
        <div>
          {address.Line1} {address.City}
        </div>
      </div>
      <div className="profile-item">Details</div>
      <div className="profile-item btns">
        <button>Save Contact</button>
        <button>Exchange Contact</button>
      </div>
      <div className="profile-item">Links</div>
      <div className="profile-item">Fields</div>
    </div>
  );
}
