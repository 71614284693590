import { useNavigate, useParams } from "react-router-dom";
import { loggedInState, useBlocker, useLogout, useRoot } from "../root-context";
import { useEffect, useState } from "react";
import { message } from "antd";

export const USER_KEY = "user-token-data";

export const ApiBaseUrl = (window as any).BasePath + "/api";
export const BaseUrl = (window as any).BasePath;

export default function useFetch(baseUrl = ApiBaseUrl) {
  const request = useRequest();
  return {
    get: (path: string, options = {}) => {
      return request(baseUrl + path, options);
    },
    post: (path: string, data: any, options = {}) => {
      return request(baseUrl + path, {
        ...options,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      });
    },
  };
}

function useRequest() {
  const navigate = useNavigate();
  const showError = useError();
  const root = useRoot();
  let { orgSlug } = useParams();
  orgSlug = orgSlug || root?.user?.OrgSubDomain || "dbc";
  const { block, unblock } = useBlocker();
  return function (
    url: string,
    { method, headers, body, fileName, isFile, query, options }: any | undefined
  ) {
    return new Promise(async (resolve, reject) => {
      !options?.skipBlocker && block();

      let token = "";
      try {
        token = acquireToken();
      } catch (error) {
        console.log("error occurred while acquiring token", error);
        // if (error.status === 404) {
        //   !options.skipBlocker && unblock();
        //   navigate("user-not-found");
        //   resolve();
        //   return;
        // }
      }
      const queryParams = query ? "?" + serializeQuery(query) : "";
      fetch(url + queryParams, {
        method: method,
        headers: {
          ...headers,
          Authorization: "bearer " + token,
        },
        body: body,
      })
        .then(function (resp) {
          if (resp.ok) {
            if (resp.status === 204) {
              resolve(null);
              return;
            }
            //   if (isFile) {
            //     resp.blob().then(function (blob) {
            //       saveAs(
            //         blob,
            //         ((fileName && `${fileName} `) || "") +
            //           moment().format("YYMMDD_HHmmss")
            //       );
            //       resolve(null);
            //     });
            //     return;
            //   }
            resp.json().then(function (data) {
              resolve(data);
            });
            return;
          } else if (resp.status === 401) {
            root?.setLoggedInStatus(loggedInState.failed);
            navigate(`/${orgSlug}/login`);
            reject(resp);
            return;
          } else if (resp.status === 403) {
            showError("You are not authorized to perform this action");
            reject(resp);
            return;
          } else if (resp.status === 400 || resp.status === 500) {
            resp
              .json()
              .then(function (data) {
                showError(
                  data.Message ||
                    "Something went wrong please contact the system administrator."
                );
              })
              .catch((e) => {
                showError(
                  "Something went wrong please contact the system administrator."
                );
              })
              .finally(() => reject(resp));
            return;
          }
          showError(
            "Something went wrong please contact the system administrator. status code:" +
              resp.status
          );
          reject(resp);
        })
        .catch((e) => {
          reject(e);
          console.log(e);
          showError(
            "Something went wrong please contact the system administrator."
          );
        })
        .finally(() => {
          !options?.skipBlocker && unblock();
        });
    });
  };
}

// export function useGet(url, query){
//   const request = useRequest()
//  const [data, setData]= useState()
//  const [state, setState]= useState<string>()
//   useEffect(function () {
//     request
//   }, [query]);
// }

export function useError() {
  const root = useRoot();

  return function (msg: string) {
    root?.messageApi.open({
      type: "error",
      content: msg,
    });
  };
}

export function useSuccess() {
  const root = useRoot();
  return function (msg: string) {
    root?.messageApi.open({
      type: "success",
      content: msg,
    });
  };
}

function acquireToken() {
  try {
    let tokenData = JSON.parse(localStorage.getItem(USER_KEY) || "");
    if (tokenData.AccessToken) {
      return tokenData.AccessToken;
    }
  } catch {}
}

function serializeQuery(obj: any) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
