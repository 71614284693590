import React from "react";
import { Button, Form, Input } from "antd";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import useFetch, { USER_KEY, useError } from "../../common/hooks/use-fetch";
import { loggedInState, useRoot } from "../../common/root-context";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAuthNavigate from "../../common/hooks/use-auth-navigate";

const Login: React.FC = () => {
  const login = useLogin();
  const { orgSlug } = useParams();

  const onFinish = async (values: any) => {
    login(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        className="auth-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ margin: "auto" }}
      >
        <h3 style={{ fontSize: "1.7rem", margin: 0 }}>Hi there,</h3>
        <p style={{ color: "#6c757d" }}>Enter your details to continue</p>
        <Form.Item
          // label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input
            prefix={<UserOutlined />}
            size="large"
            placeholder="Enter your email or card id"
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: "4px" }}
          // label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<KeyOutlined />}
            size="large"
            placeholder="Enter your password"
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <Link to={`/${orgSlug}/forgot-password`}>Forgot password?</Link>
          <Link to="https://wa.me/+923356656657" target="_blank">
            Need help?
          </Link>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-main"
            style={{
              background: "linear-gradient(90deg,#e72b2b,#263758)",
            }}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export function useLogin() {
  const { post } = useFetch();
  const root = useRoot();
  const navigate = useNavigate();
  const authNavigate = useAuthNavigate();
  const showError = useError();
  return async function (values: { username: string; password: string }) {
    root?.setLoggedInStatus(loggedInState.inprogress);
    try {
      const resp = (await post("/auth/login", values, {
        anonymous: true,
      })) as any;
      if (resp) {
        if (resp.EmailToken) {
          root?.setLoggedInStatus(loggedInState.failed);
          authNavigate(
            `/configure-email?t=${resp.EmailToken}&uid=${resp.UserId}`
          );
          return;
        }
        localStorage.setItem(USER_KEY, JSON.stringify(resp));
        root?.setLoggedInStatus(loggedInState.success);
        navigate("/");
        return;
      }
    } catch (error: any) {
      if (error.status === 401) {
        showError("Username or password id invalid.");
      }
    }
    root?.setLoggedInStatus(loggedInState.failed);
  };
}

export default Login;
